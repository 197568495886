import { SelectInput } from "components/UI";
import { CustomButton } from "components/UI";
import { TextInput } from "components/UI";
import { CustomForm } from "components/UI";
import { formatNumber } from "components/UI/helpers";
import React, { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Card, CardTitle, Col, FormGroup, Row } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { CURRENCY } from "variables";
import { getLoanTypesList } from "../loan-setup/api";
import { useHistory } from "react-router";
import BackButton from "components/UI/BackButton";
import { getRoleLink } from "views/helper";
import { getMembers } from "redux/actions/society";
import { useDispatch } from "react-redux";
// import UseAutocomplete from "components/UI/Autocomplete";
import { getBankListFW } from "redux/actions/account";
import { _formatNumber } from "components/UI/helpers";
import MemberListInput from "../utilities/MemberListInput";
import { _fetchApi } from "redux/actions/api";
// import { _fetchApi } from 'redux/actions/api'
function NewLoanApplication() {
  const history = useHistory();
  const { members } = useSelector((state) => state.society);
  const { user, society } = useSelector((state) => state.auth);
  const [loanTypes, setLoanTypes] = useState([]);
  const [selectedLoanInfo, setSelectedLoanInfo] = useState({});
  const [form, setForm] = useState({
    grantor1_id: "",
    grantor2_id: "",
    grantor1: "",
    grantor2: "",
    user_id: user.id,
  });
  const [grantorName1, setGrantorName1] = useState();
  const [grantorName2, setGrantorName2] = useState();
  const [loading, setLoading] = useState(false);
  const [selectMembers, setSelectMembers] = useState({});
  const [banks, setBanks] = useState([]);
  const [getData, setGetData] = useState({});

  // const society = useSelector((state) => state.auth.society);

  const dispatch = useDispatch();
  const getLoanList = () => {
    getLoanTypesList((d) => {
      const ddd = d?.filter((i) => i?.status === "active");
      setLoanTypes(ddd);
    });
  };

  const _getMembers = useCallback(() => {
    dispatch(getMembers(society.id));
  }, [society.id]);

  const getMembersData = useCallback(() => {
    _fetchApi(
      `/societies/by-one-member?user_id=${selectMembers.id}&society_id=${society.id}`,
      (res) => {
        if (res.success) {
          let data = res.data ? res.data[0] : {};
          setGetData(data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [selectMembers.id]);

  useEffect(() => {
    getMembers();
    getBankListFW((l) => setBanks(l.data));
  }, []);

  useEffect(() => {
    getLoanList();
    _getMembers();
    getMembersData();
  }, [_getMembers, getMembersData]);

  useEffect(() => {
    if (!Object.keys(selectMembers).length) {
      setSelectMembers({ ...user, user_id: user.id });
    }
  }, [form.user_id]);
  const formFields = [
    // {
    //   name: 'amount',
    //   label: `Enter loan amount (${CURRENCY}${formatNumber(form.amount)})`,
    //   value: form.amount,
    // },
    {
      name: "account_no",
      label: "Account No.",
      value: form.account_no,
    },
    {
      name: "account_name",
      label: "Account Name",
      value: form.account_name,
    },
    {
      type: "custom",
      component: (item) => (
        <FormGroup>
          <label className="font-weight-bold">Bank Name</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>
              setForm((p) => ({ ...p, bank_name: value }))
            }
          >
            <option>--select---</option>
            {banks &&
              banks.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
          </select>
        </FormGroup>
      ),
    },
  ];
  const newGuarantor = members.filter((item) => item.user_id === user.id)[0];

  let guarantor_needed = selectedLoanInfo?.guarantor === "No";
  const isValid = guarantor_needed
    ? false
    : form?.grantor2_id === "" || form?.grantor1_id === "";
  const checkGrantors = form?.grantor1_id === form?.grantor2_id;

  const submitLoanApplication = () => {
    setLoading(true);
    if (Object.keys(selectedLoanInfo).length) {
      if (!isValid) {
        if (checkGrantors && !guarantor_needed) {
          toast.error("You cannot choose the same guarantor");
          setLoading(false);
        } else {
          const grantor1 = guarantor_needed ? newGuarantor : grantorName1;
          const grantor2 = guarantor_needed ? newGuarantor : grantorName2;

          let grantors = [
            {
              ...grantor1,
              applicant_id: selectMembers.id,
              society_id: society.id,
              loan_id: selectedLoanInfo.id,
              member_id: society.membership_id,
              loan_amount: form.amount,
              grantor_id: guarantor_needed ? user.id : form.grantor1_id,
              status: guarantor_needed ? "Approve" : "Pending",
            },
            {
              ...grantor2,
              applicant_id: selectMembers.id,
              member_id: society.membership_id,
              society_id: society.id,
              loan_id: selectedLoanInfo.id,
              loan_amount: form.amount,
              grantor_id: guarantor_needed ? user.id : form.grantor2_id,
              status: guarantor_needed ? "Approve" : "Pending",
            },
          ];

          _postApi(
            `/loan-application/new`,
            {
              member_id: selectMembers.id,
              member_name: `${selectMembers.firstname} ${selectMembers.lastname}`,
              society_id: society.id,
              grantors,
              ...form,
              account_head: selectedLoanInfo.account_head,
              loan_info: selectedLoanInfo.loan_info,
              loan_title: selectedLoanInfo.loan_title,
              loan_setup_id: selectedLoanInfo.id,
              status: guarantor_needed ? "Approved" : "guarantor_pending",
              grantor1: guarantor_needed
                ? user.firstname + " " + user.lastname
                : form.grantor1,
              grantor2: guarantor_needed
                ? user.firstname + " " + user.lastname
                : form.grantor2,
              grantor1_id: guarantor_needed ? user.id : form.grantor1_id,
              grantor2_id: guarantor_needed ? user.id : form.grantor2_id,
              grantor1_status: guarantor_needed ? "Approved" : "Pending",
              grantor2_status: guarantor_needed ? "Approved" : "Pending",
            },
            (d) => {
              if (d && d.success) {
                toast.success(d.message);
                history.push(getRoleLink("/loan/my-loans"));
                setLoading(false);
              } else {
                toast.error(d.message);
                setLoading(false);
              }
            },
            (e) => {
              toast.error(e.message);
              setLoading(false);
            }
          );
        }
      } else {
        toast.error("Please Select Guarantor");
        setLoading(false);
      }
    } else {
      toast.error("Please Select Loan Type");
      setLoading(false);
    }
  };

  useEffect(() => {
    let total_interest =
      selectedLoanInfo?.interest_rate_type?.toLowerCase() === "percentage"
        ? (parseFloat(selectedLoanInfo?.interest_rate) / 100) *
          parseFloat(form?.amount)
        : selectedLoanInfo?.interest_rate;
    let total_admin_charges =
      selectedLoanInfo?.finetype?.toLowerCase() === "percentage"
        ? (parseFloat(selectedLoanInfo?.admin_charges) / 100) *
          parseFloat(form?.amount)
        : selectedLoanInfo?.admin_charges;
    setForm((p) => ({
      ...p,
      total_interest: parseFloat(total_interest || 0).toFixed(2),
      total_admin_charges: parseFloat(total_admin_charges || 0).toFixed(2),
    }));
  }, [form?.amount]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "amount") {
      console.log(selectedLoanInfo.max_amount);
      if (
        parseFloat(value).toFixed(2) >
        parseFloat(
          selectedLoanInfo?.max_amount_type?.toLowerCase() === "fixed"
            ? parseFloat(selectedLoanInfo.max_amount)
            : (parseFloat(selectedLoanInfo.max_amount) / 100) *
                parseFloat(getData.contribution_balance).toFixed(2)
        )
      ) {
        console.log(selectedLoanInfo.max_amount);
        toast.success("You exceed maximun loan amount");
      } else {
        setForm((p) => ({
          ...p,
          [name]: value,
          period_value: selectedLoanInfo.period_value,
          period_duration: selectedLoanInfo.period_duration,
        }));
      }
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  useEffect(() => {
    getBankListFW((l) => setBanks(l.data));
  }, []);

  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 d-flex justify-content-between ">
          <div> New Loan Application</div>
          {user.role === "Admin" && (
            <MemberListInput
              value={form.user_id}
              onChange={(e) => {
                let value = e.target.value;
                const selected = members.find((e) => e.user_id === value);

                setForm((p) => ({ ...p, user_id: value }));
                setSelectMembers({ ...selected, id: selected.user_id });
              }}
            />
          )}
        </CardTitle>

        {/* {JSON.stringify(form)} */}
        <div>
          <SelectInput
            container="col-md-4"
            label={"Select Loan Type"}
            options={loanTypes.map((a) => a.loan_title)}
            name="loan_type"
            onChange={({ target: { value } }) => {
              let selected = loanTypes.find((a) => a.loan_title === value);
              setSelectedLoanInfo(selected);
              setForm((p) => ({ ...p, loan_type: value, amount: "" }));
            }}
            value={form.loan_type}
          />
        </div>
        {/* {members.filter((a)=>a.user_id!==form?.grantor2_id)} */}
        {form.loan_type && (
          <Card className="p-2 mx-3">
            <h5>Detailed Information</h5>
            <Row>
              {/* {JSON.stringify(selectedLoanInfo)} */}
              <Col md={4} className="my-2">
                Name: {selectedLoanInfo.loan_title}
              </Col>
              <Col md={4} className="my-2">
                Minimum Amount: {CURRENCY}
                {_formatNumber(selectedLoanInfo.min_amount)}
              </Col>
              <Col md={4} className="my-2">
                Maximum Amount: {CURRENCY}
                {selectedLoanInfo?.max_amount_type?.toLowerCase() ===
                "percentage"
                  ? _formatNumber(
                      (selectedLoanInfo.max_amount / 100) *
                        parseFloat(getData.contribution_balance)
                    )
                  : _formatNumber(selectedLoanInfo.max_amount)}
              </Col>
              <Col md={4} className="my-2">
                Period: {selectedLoanInfo.period_value}
                {selectedLoanInfo.period_duration}
              </Col>
              <Col md={4} className="my-2">
                Interest Rate:{" "}
                {selectedLoanInfo.interest_rate_type.toLowerCase() ===
                "percentage"
                  ? `${selectedLoanInfo.interest_rate}%`
                  : `${CURRENCY}${selectedLoanInfo.interest_rate}`}
              </Col>
              <Col md={4} className="my-2">
                Admin Charge:
                {selectedLoanInfo.finetype.toLowerCase() === "percentage"
                  ? `${selectedLoanInfo.admin_charges}%`
                  : `${CURRENCY}${selectedLoanInfo.admin_charges}`}
              </Col>
            </Row>
          </Card>
        )}

        <Row className="mx-0">
          <TextInput
            label={` Enter loan amount (${CURRENCY}${formatNumber(
              form.amount
            )})`}
            autocomplete="false"
            type="number"
            onChange={handleChange}
            name="amount"
            value={form.amount}
            container="col-md-4"
          />
          <TextInput
            label={`Total Interest`}
            disabled
            name="total_interest"
            value={_formatNumber(form.total_interest)}
            container="col-md-4"
          />
          {/* <UseAutocomplete
            options={members.filter((a) => a.user_id !== user.id)}
            renderElement={members.filter((a) => a.user_id !== user.id).map((item, index) => (
              <li >{`${item.firstname} ${item.lastname}(${item.user_id})`}</li>
            ))}
            getOptionLabel={(item) =>
              `${item.firstname} ${item.lastname}(${item.user_id})`
            }
          /> */}
          {/* <SelectInput
            container="col-md-2"
            label={"Guarantor Name 1"}
            options={members
              .filter((a) => a.user_id !== user.id)
              .map((a) => `${a.firstname} ${a.lastname}(${a.user_id})`)}
            name="grantor1"
            onChange={({ target: { value } }) => {
              let arr = value.split("(");
              let user_id = arr[arr.length - 1]?.split(")")[0];
              let selected = members.find((a) => a.user_id === user_id);
              setGrantorName1(selected);
              setForm((p) => ({ ...p, grantor1: value, grantor1_id: user_id }));
            }}
            value={form.grantor1}
          />
          <SelectInput
            container="col-md-2"
            label={"Guarantor Name 2"}
            options={members
              .filter((a) => a.user_id !== user.id)
              .map((a) => `${a.firstname} ${a.lastname}(${a.user_id})`)}
            name="grantor2"
            onChange={({ target: { value } }) => {
              let arr = value.split("(");
              let user_id = arr[arr.length - 1]?.split(")")[0];
              let selected = members.find((a) => a.user_id === user_id);
              setGrantorName2(selected);
              setForm((p) => ({ ...p, grantor2: value, grantor2_id: user_id }));
            }}
            value={form.grantor2}
          /> */}

          {/* Check if the guarantor_required value is "yes" */}
          {!guarantor_needed ? (
            <>
              <div className="col-md-2">
                <SelectInput
                  container="col-md-12"
                  label={"First Guarantor"}
                  options={members
                    ?.filter((a) => a.user_id !== user.id)
                    ?.map((item) => `${item.firstname} ${item.lastname}`)}
                  name="grantor1_id"
                  onChange={({ target: { value } }) => {
                    let selected = members.find(
                      (a) => `${a.firstname} ${a.lastname}` === value
                    );
                    setGrantorName1(selected);
                    setForm((p) => ({
                      ...p,
                      grantor1_id: selected.user_id,
                    }));
                  }}
                />
              </div>
              <div className="col-md-2">
                <SelectInput
                  container="col-md-12"
                  label={"Second Guarantor"}
                  options={members
                    ?.filter((a) => a.user_id !== user.id)
                    ?.map((item) => `${item.firstname} ${item.lastname}`)}
                  name="grantor2_id"
                  onChange={({ target: { value } }) => {
                    let selected = members.find(
                      (a) => `${a.firstname} ${a.lastname}` === value
                    );
                    setGrantorName2(selected);
                    setForm((p) => ({
                      ...p,
                      grantor2_id: selected.user_id,
                    }));
                  }}
                />
              </div>
            </>
          ) : (
            <div className="col-md-4">
              No guarantors required. Application will be automatically
              approved.
            </div>
          )}
        </Row>

        <h5 className="ml-3">Account Information</h5>
        <CustomForm fields={formFields} handleChange={handleChange} />

        <center>
          <CustomButton onClick={submitLoanApplication} loading={loading}>
            Submit Loan Application
          </CustomButton>
        </center>
      </Card>
    </>
  );
}

export default NewLoanApplication;
