import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory, useLocation } from "react-router";
import { BorderedCard } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import CustomCard from "components/UI/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoanSchedule,
  getLoanScheduleByStatus,
  newLoanRepayment,
} from "./api";
import { Checkbox } from "components/UI";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { CURRENCY } from "variables";
import toast, { Toaster } from "react-hot-toast";
import { getPendingLoanSchedule } from "redux/actions/account";
import types from "redux/actions/types";
import { getRoleLink } from "views/helper";
import { EmptyList } from "../Admin/ReportDetails";
import { _formatNumber } from "components/UI/helpers";
import { SelectInput } from "components/UI";
import { TextInput } from "components/UI";
import { Link } from "react-router-dom";
import CustomModal from "components/UI/CustomModal";
import RepaymentSchedule from "./RepaymentSchedule";
import { CustomTable } from "components/UI";
import { AiFillDelete } from "react-icons/ai";
import ModalNotification from "components/UI/ModalNotification";
import { apiURL } from "variables";
import { flutterwaveObj } from "components/UI/helpers";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { _approvePendingPayments } from "../monthlyContribution/api";
import MemberListInput from "../utilities/MemberListInput";

export default function LoanRepayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment, selectedPayment },
    society: { members },
  } = useSelector((s) => s);

  const [pendingLoanList, setPendingLoanList] = useState([]);
  const location = useLocation();

  const [selectMembers, setSelectMembers] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedLoanInfo, setSelectedLoanInfo] = useState({});
  const [form, setForm] = useState({ amount: "", user_id: user.id });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [reference, setReference] = useState("");
  const [req_id, setReqId] = useState("");
  const [onlinePaymentLoading, setOnlinePaymentLoading] = useState(false);
  const handleChecked = (i) => {
    // console.log(i)
    if (
      selectedLoanPayment.findIndex(
        (a) => a.loan_id === i.loan_id && a.schedule_date === i.schedule_date
      ) === -1
    ) {
      let list = [
        ...selectedLoanPayment,
        { ...i, account_description: i.loan_title },
      ];
      dispatch({ type: types.loan.SET_SELECTED_LOAN_PAYMENT, payload: list });
      // setSelected((p) => [...p, i])
    } else {
      let filtered = selectedLoanPayment.filter(
        (a) => a.loan_id === i.loan_id && a.schedule_date !== i.schedule_date
      );
      dispatch({
        type: types.loan.SET_SELECTED_LOAN_PAYMENT,
        payload: filtered,
      });
      // setSelected(filtered)
    }
  };

  // const getData = useCallback(() => {
  //   getLoanScheduleByStatus(
  //     { user_id: selectMembers.id, society_id: society.id },
  //     (d) => {
  //       if (d && d.data && d.data.length) {
  //         let listByLoan = {}
  //         d.data.forEach((sch) => {
  //           let loan_id = sch.loan_id
  //           if (Object.keys(listByLoan).includes(loan_id)) {
  //             listByLoan[loan_id] = [...listByLoan[loan_id], sch]
  //           } else {
  //             listByLoan[loan_id] = [sch]
  //           }
  //         })
  //         setScheduleData(listByLoan)
  //         setActiveTab(Object.keys(listByLoan)[0])
  //       }
  //     },
  //   )
  // }, [selectMembers.id, society.id])

  // useEffect(() => {
  //   dispatch(getPendingLoanSchedule((defaultTab) => setActiveTab(defaultTab)));
  // }, [dispatch]);

  // const totalAmountSelected = selectedLoanPayment.reduce(
  //   (a, b) => a + parseFloat(b.amount),
  //   0
  // );
  const handleDelete = (id) => {
    let newSelect = selectedLoanPayment.filter((i, index) => index !== id);
    dispatch({
      type: types.loan.SET_SELECTED_LOAN_PAYMENT,
      payload: newSelect,
    });
  };
  const tableFields = [
    // {
    //   title: 'Loan ID',
    //   custom: true,
    //   component: (i) => <div>{i.loan_id}</div>,
    // },
    {
      title: "Date",
      custom: true,
      component: (i) => <div>{moment().format("YYYY-MM-DD")}</div>,
    },
    {
      title: "Description",
      custom: true,
      component: (i) => <div>{i.loan_title}</div>,
    },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (i) => (
        <div className="text-right">{_formatNumber(i.amount)}</div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (i, index) => (
        <div>
          <Button size="sm" onClick={() => handleDelete(index)} color="danger">
            <AiFillDelete />{" "}
          </Button>
        </div>
      ),
    },
  ];

  const totalAmount = selectedLoanPayment.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );
  const getPendingLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/by-status/by_loan/${society.id}?member_id=${selectMembers.id}&query_type=get_available_loan`,
      (d) => {
        if (d && d.data) {
          setPendingLoanList(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society.id, selectMembers.id]);

  useEffect(() => {
    getPendingLoanList();
  }, [getPendingLoanList]);

  useEffect(() => {
    if (!Object.keys(selectMembers).length) {
      setSelectMembers({ ...user, user_id: user.id });
    }
  }, [form.user_id]);

  const addToList = () => {
    if (!form.amount > 0) {
      toast.error("Please input amount");
    } else if (!Object.keys(selectedLoanInfo).length) {
      toast.error("Please select loan");
    } else {
      dispatch({
        type: types.loan.SET_SELECTED_LOAN_PAYMENT,
        payload: [
          {
            ...selectedLoanInfo,
            ...form,
          },
          ...selectedLoanPayment,
        ],
      });
      setForm((p) => ({
        amount: "",
        loan: "",
      }));
      setSelectedLoanInfo((p) => ({ loan_title: "", loan_id: "" }));
    }
  };

  const handleAttachment = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files;
      setAttachment(Object.values(fileObj));
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);

      setImageSrc(imageUrl);
    }
  };

  const payWithFlutterWave = (payload) => {
    if (form.amount > 0) {
      setLoading(true);
      let formData = new FormData();
      formData.append(
        "payment_items",
        JSON.stringify([{ ...selectedLoanInfo, ...form }])
      );
      formData.append(
        "member_name",
        selectMembers.firstname + " " + selectMembers.lastname
      );
      formData.append("society_id", society.id);
      formData.append("member_id", selectMembers.id);
      formData.append("total_amount", form.amount);
      formData.append("loan_id", form.loan_id);
      formData.append("req_status", "waiting");
      if (attachment && attachment.length) {
        attachment.forEach((file) => formData.append("attachments", file));
      }
      fetch(`${apiURL}/loan-repayment/new-payment-request`, {
        method: "POST",
        body: formData,
      })
        .then((raw) => raw.json())
        .then((resp) => {
          if (resp.success) {
            setReference(resp.reference);
            setReqId(resp.req_id);
            payload(resp);
          } else {
            toast.error(resp.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(e.message);
          setLoading(false);
        });
    } else {
      toast.error("Please put amount");
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    if (form.amount > 0) {
      if (attachment.length) {
        let formData = new FormData();
        formData.append(
          "payment_items",
          JSON.stringify([{ ...selectedLoanInfo, ...form }])
        );
        formData.append(
          "member_name",
          selectMembers.firstname + " " + selectMembers.lastname
        );
        formData.append("society_id", society.id);
        formData.append("member_id", selectMembers.id);
        formData.append("total_amount", form.amount);
        formData.append("loan_id", form.loan_id);
        if (attachment && attachment.length) {
          attachment.forEach((file) => formData.append("attachments", file));
        }
        console.log(formData);
        fetch(`${apiURL}/loan-repayment/new-payment-request`, {
          method: "POST",
          body: formData,
        })
          .then((raw) => raw.json())
          .then((resp) => {
            if (resp.success) {
              // navigate back to dashboard
              toast.success(resp.message);
              history.push(getRoleLink("/dashboard"));
              dispatch({ type: types.loan.CLEAR_LOAN_PAYMENT_SELECTION });
              setLoading(false);
            } else {
              toast.error(resp.message);
              setLoading(false);
            }
          })
          .catch((e) => {
            toast.error(e.message);
            setLoading(false);
          });
      }
    } else {
      toast.error("Please put amount");
      setLoading(false);
    }
  };

  const handleFlutterPayment = useFlutterwave(
    flutterwaveObj(
      society.public_key,
      ((parseFloat(form.amount) * 1.7) / 100 >= 2000
        ? 2000
        : (parseFloat(form.amount) * 1.7) / 100) +
        parseFloat(form.amount) +
        50,
      society.society_logo,
      selectMembers,
      "Loan Repayment",
      {
        type: "Loan Repayment",
        id: selectMembers.id,
        society_id: society.id,
        selectedPayment: JSON.stringify(selectedLoanPayment),
        reference,
        req_id,
        total_amount: selectedLoanPayment.reduce(
          (a, b) => a + parseFloat(b.amount),
          0
        ),
      },
      society.sub_account_id,
      society.settlement_type
    )
  );

  const handleApproval = (response, resp) => {
    console.log({ response, resp });
    // const arr = [];
    // selectedLoanPayment?.forEach((item) => {
    //   arr.push({ ...item, resp: resp.req_id, resp: resp.reference });
    // });
    setLoading(true);
    _approvePendingPayments(
      resp.req_id,
      {
        ...selectedLoanPayment[0],
        approved_by: selectMembers.id,
        total_amount: response.amount,
        requestInfo: [{ ...selectedLoanInfo, ...form }],
        ...response,
        ...resp,
        society_id: society.id,
        member_id: selectMembers.id,
        loan_id: selectedLoanInfo.loan_id,
      },
      (d) => {
        console.log(d);
        setLoading(false);
        if (d.success) {
          dispatch({ type: types.loan.CLEAR_LOAN_PAYMENT_SELECTION });
          history.push(getRoleLink("/dashboard"));
          setLoading(false);
          closePaymentModal();
          toast.success("Transaction successfull");
        }
      },
      (e) => {
        console.log(e);
        setLoading(false);
        if (e.success) {
          toast.success(e.message);
          getRoutes("/dashboard");
        } else {
          toast.error(e.message);
        }
      }
    );
  };

  return (
    <>
      <Card body>
        <CardTitle className="h4 mb-4 text-center d-flex justify-content-between">
          <div>Loan Repayment</div>
          {user.role === "Admin" && (
            <MemberListInput
              value={form.user_id}
              onChange={(e) => {
                let value = e.target.value;
                const selected = members.find((e) => e.user_id === value);

                setForm((p) => ({ ...p, user_id: value }));
                setSelectMembers({ ...selected, id: selected.user_id });
              }}
            />
          )}
        </CardTitle>
        <CardBody>
          <div className="row">
            <SelectInput
              container="col-md-4"
              label={"Select Loan "}
              options={pendingLoanList.map(
                (a) => a.loan_title + `(${a.loan_id})`
              )}
              name="loan"
              onChange={({ target: { value } }) => {
                let selected = pendingLoanList.find(
                  (a) => a.loan_title + `(${a.loan_id})` === value
                );
                setSelectedLoanInfo(selected);
                setForm((p) => ({
                  ...p,
                  loan_id: selected?.loan_id,
                  amount: "",
                }));
              }}
              value={form.loan}
            />
            <TextInput
              onChange={({ target: { value, name } }) =>
                setForm((p) => ({ ...p, [name]: value }))
              }
              label="Amount"
              value={form.amount}
              name="amount"
              container="col-md-4"
              type="number"
            />
            <TextInput
              onChange={({ target: { value, name } }) =>
                setForm((p) => ({ ...p, [name]: value }))
              }
              label="Date"
              value={moment().format("YYYY-MM-DD")}
              container="col-md-4"
              type="date"
              disabled
            />
          </div>
          {form.loan_id && (
            <>
              <Card className="p-2 mx-0">
                <h5>
                  <b>Detailed Information</b>
                </h5>
                <Row>
                  <Col md={4} className="my-2">
                    Loan Name: {selectedLoanInfo?.loan_title}
                  </Col>
                  <Col md={4} className="my-2">
                    Loan Amount: {CURRENCY}
                    {_formatNumber(selectedLoanInfo?.amount)}
                  </Col>
                  <Col md={4} className="my-2">
                    Amount Paid: {CURRENCY}
                    {_formatNumber(selectedLoanInfo?.amount_paid)}
                  </Col>
                  <Col md={4} className="my-2">
                    Loan Balance : {CURRENCY}
                    {_formatNumber(
                      selectedLoanInfo?.amount - selectedLoanInfo?.amount_paid
                    )}
                  </Col>
                  <Col md={4} className="my-2">
                    Loan Interest: {CURRENCY}
                    {_formatNumber(selectedLoanInfo?.total_interest)}
                  </Col>
                  <Col md={4} className="my-2">
                    Loan Period: {selectedLoanInfo?.period_value}
                    {selectedLoanInfo?.period_duration}
                  </Col>
                </Row>
              </Card>
              <Row
                className="m-1"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  onClick={() => {
                    setModalIsOpen(true);
                    history.push(
                      getRoleLink(
                        `/loan-repayment?loan_id=${selectedLoanInfo.loan_id}`
                      )
                    );
                  }}
                >
                  <Link>
                    <u>
                      <b>View schedule</b>
                    </u>
                  </Link>
                </div>
                <div>
                  <label htmlFor="receipt">Attach Evidence of Payment</label>
                  <Input
                    id="receipt"
                    type="file"
                    onChange={handleAttachment}
                    multiple
                  />
                </div>
              </Row>
              <center>
                <CustomButton
                  loading={loading}
                  onClick={() => {
                    attachment.length
                      ? setApprovalConfirmation(true)
                      : setOnlinePaymentLoading(true);
                  }}
                >
                  {attachment.length ? "Submit Payment Request" : "Pay Now"}
                </CustomButton>
              </center>
              <div>
                {imageSrc && (
                  <img
                    src={imageSrc}
                    alt="Selected Image"
                    className="img-fluid"
                  />
                )}
              </div>
            </>
          )}

          {/* <center>
            <CustomButton onClick={addToList}>Add</CustomButton>
          </center>
          <CustomTable
            fields={tableFields}
            data={selectedLoanPayment}
            total={["", "Total", _formatNumber(totalAmount)]}
          /> */}

          <ModalNotification
            isOpen={approvalConfirmation}
            confirm={handleSubmit}
            confirmLoading={loading}
            toggle={() => setApprovalConfirmation((p) => !p)}
          >
            <h3 className="text-center">
              Are you sure you want to make payment of a total sum of {CURRENCY}
              {_formatNumber(form.amount)}
            </h3>
          </ModalNotification>

          <ModalNotification
            isOpen={onlinePaymentLoading}
            confirm={() =>
              payWithFlutterWave((resp) =>
                handleFlutterPayment({
                  callback: (response) => {
                    handleApproval(response, resp);
                  },
                  onClose: () => {
                    setLoading(false);
                  },
                })
              )
            }
            confirmLoading={loading}
            confirmText={
              <div>
                {" "}
                Pay Now (
                <b>
                  {_formatNumber(
                    ((parseFloat(form.amount) * 1.7) / 100 >= 2000
                      ? 2000
                      : (parseFloat(form.amount) * 1.7) / 100) +
                      parseFloat(form.amount) +
                      50
                  )}
                </b>{" "}
                )
              </div>
            }
            headerText={<b>Payment Confirmation</b>}
            toggle={() => setOnlinePaymentLoading((p) => !p)}
          >
            <Table bordered size="sm" responsive>
              <thead>
                <th className="text-left">Description</th>
                <th>Amount({CURRENCY})</th>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left ">{selectedLoanInfo?.loan_title}</td>
                  <td className="text-right">{_formatNumber(form.amount)}</td>
                </tr>
                <tr>
                  <td className="text-left ">Switch fee</td>
                  <td className="text-right ">
                    {_formatNumber(
                      (parseFloat(form.amount) * 1.7) / 100 >= 2000
                        ? 2000
                        : (parseFloat(form.amount) * 1.7) / 100
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="text-left">Stamp duty</td>
                  <td className="text-right">{"50.00"}</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="text-right">
                    <b>
                      {_formatNumber(
                        ((parseFloat(form.amount) * 1.7) / 100 >= 2000
                          ? 2000
                          : (parseFloat(form.amount) * 1.7) / 100) +
                          parseFloat(form.amount) +
                          50
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalNotification>
        </CardBody>
      </Card>
      <CustomModal
        header="Repayment Schedule"
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(!modalIsOpen)}
      >
        <RepaymentSchedule />
      </CustomModal>
    </>
  );
}

// <div>
// {Object.keys(loanScheduleByLoan).length ? (
//   <>
//     <div className="d-flex flex-direction-row justify-content-end align-items-center">
//       <CustomButton
//         onClick={() =>
//           history.push(getRoleLink(`/loan-repayment-confirm?loan_id`))
//         }
//         loading={loading}
//       >
//         Save ({CURRENCY}
//         {_formatNumber(totalAmountSelected)})
//       </CustomButton>
//     </div>
//     <Nav tabs>
//       {Object.keys(loanScheduleByLoan).map((i, id) => (
//         <NavItem>
//           <NavLink
//             className={activeTab === i ? "active" : ""}
//             onClick={() => setActiveTab(i)}
//             key={id}
//           >
//             {i}
//           </NavLink>
//         </NavItem>
//       ))}
//     </Nav>
//     <TabContent activeTab={activeTab}>
//       {/* {JSON.stringify(loanScheduleByLoan)} */}
//       {Object.keys(loanScheduleByLoan).map((i, id) => {
//         return (
//           <TabPane tabId={i}>
//             <Table>
//               <thead>
//                 <tr>
//                   <th>Select</th>
//                   <th>Month, Year</th>
//                   <th>Amount Due</th>
//                   <th>Fine</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {loanScheduleByLoan[i].map((item, ix) => {
//                   let checked =
//                     selectedLoanPayment.findIndex(
//                       (a) =>
//                         a.loan_id === item.loan_id &&
//                         a.schedule_date === item.schedule_date
//                     ) !== -1;
//                   return (
//                     <tr key={ix}>
//                       <td>
//                         <Checkbox
//                           id={ix}
//                           name={i + ix}
//                           checked={checked}
//                           onChange={() => handleChecked(item)}
//                         />
//                       </td>
//                       <td>
//                         {/* {item.schedule_month}, */}
//                         {/* {JSON.stringify(item)} */}
//                         {moment(item.schedule_date).format(
//                           "MMM, YYYY"
//                         )}
//                       </td>
//                       <td>
//                         {_formatNumber(item.amount).toLocaleString(
//                           "en-US",
//                           {
//                             minimumFractionDigits: 2,
//                           }
//                         )}
//                       </td>
//                       <td>{_formatNumber(item.fine)} </td>
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </Table>
//           </TabPane>
//         );
//       })}
//     </TabContent>
//   </>
// ) : (
//   <EmptyList message='You currently do not have any loan to repay, kindly click "My Loans" on the menu to apply for loan.' />
// )}
// </div>
