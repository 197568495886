import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "components/UI/CustomTable";
import { Button, Card, CardBody, CardTitle, Col } from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory } from "react-router";
import { _fetchApi } from "redux/actions/api";
import CustomCard from "components/UI/CustomCard";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { EmptyList } from "../Admin/ReportDetails";
import { formatNumber } from "components/UI/helpers";
import moment from "moment";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { getRoleLink } from "views/helper";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { today } from "utils/helpers";

export default function LoanDisbursement() {
  const history = useHistory();
  const society = useSelector((state) => state.auth.society);

  const [pendingLoanList, setPendingLoanList] = useState([]);
  const [dateInfo, setDateInfo] = useState({ from: today, to: today });

  const handleChange = ({ target: { name, value } }) => {
    setDateInfo((p) => ({ ...p, [name]: value }));
  };

  const getPendingLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/by-status/Approved/${society.id}?from=${dateInfo.from}&to=${dateInfo.to}`,
      (d) => {
        if (d && d.data) {
          setPendingLoanList(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society, dateInfo]);

  useEffect(() => {
    getPendingLoanList();
  }, [getPendingLoanList]);

  const fields = [
    {
      title: "Application Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Loan No", value: "loan_id" },
    { title: "Applicant Name", value: "member_name" },
    {
      title: "Approved Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Total Interest",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.total_interest?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Total Admin Charges",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.total_admin_charges.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (i) => <div className="text-center">{i.status}</div>,
    },
    {
      title: "Action",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            color="info"
            className="mr-1"
            onClick={() =>
              history.push(
                getRoleLink("/loan_disbursement_form?loan_id=" + i.loan_id)
              )
            }
          >
            VIEW DETAILS
          </CustomButton>
          {/* <Button size='sm' color='success' className='mr-1'
            onClick={() => approveLoan(i)}
          >
            APPROVE
          </Button>
          <Button size='sm' color='danger'
            onClick={() => rejectLoan(i)}
          >
            REJECT
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <>
      {/* {JSON.stringify(pendingLoanList)} */}
      <Card body>
        <CardTitle className="text-center h4 mb-2">
          Pending Disbursements
        </CardTitle>
        <SearchBar placeholder="Search for a member" className="mt-1" />
        <DaterangeSelector
          from={dateInfo.from}
          to={dateInfo.to}
          handleChange={handleChange}
        />
        <CustomTable fields={fields} data={pendingLoanList} size="sm" />
        {pendingLoanList.length ? null : (
          <EmptyList message="There are no pending disbursement, check back later." />
        )}
      </Card>
      <Toaster />
    </>
  );
}

// CALL loan_payment('disbursement', '5', '34', '',
// '6', 'Normal Loan', '19200.00', '2023-09-04 02:26:02', 'Disbursed', '', 'BIT-LN-48',
// '', '34','19','800.00','20000.00')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Oct',
//       '2023-10-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Nov',
//       '2023-11-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Dec',
//       '2023-12-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Jan',
//       '2024-01-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Feb',
//       '2024-02-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Mar',
//       '2024-03-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Apr',
//       '2024-04-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'May',
//       '2024-05-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Jun',
//       '2024-06-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Jul',
//       '2024-07-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Aug',
//       '2024-08-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')
// Executing (default): CALL query_loan_schedule(  'new', '34', 'Sep',
//       '2024-09-01', 'BIT-LN-48', 'unpaid', '2023-09-04', '5',
//       1666.6666666666667, '6', 'Normal Loan', '2000', '')

//  CALL query_loan_schedule(  'new', '191', 'Oct',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');

//        CALL query_loan_schedule(  'new', '191', 'Nov',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');

//        CALL query_loan_schedule(  'new', '191', 'Dec',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');

//        CALL query_loan_schedule(  'new', '191', 'Jan',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');

//        CALL query_loan_schedule(  'new', '191', 'Feb',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Mar',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Apr',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'May',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Jun',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Jul',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Aug',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '191', 'Sep',
//       '2023-10-01', 'BIT-LN-61', 'unpaid', '2023-09-06', '19',
//       122500, 'l-14', 'Normal Loan', '1000', '');

// one million

//  CALL query_loan_schedule(  'new', '47', 'Sep', '2023-09-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//    CALL query_loan_schedule(  'new', '47', 'Oct', '2023-10-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//      CALL query_loan_schedule(  'new', '47', 'Nov', '2023-11-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//     CALL query_loan_schedule(  'new', '47', 'Dec', '2023-12-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//      CALL query_loan_schedule(  'new', '47', 'Jan', '2024-01-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//       CALL query_loan_schedule(  'new', '47', 'Feb', '2024-02-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//        CALL query_loan_schedule(  'new', '47', 'Mar', '2024-03-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//         CALL query_loan_schedule(  'new', '47', 'Apr', '2024-04-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//          CALL query_loan_schedule(  'new', '47', 'May', '2024-05-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//           CALL query_loan_schedule(  'new', '47', 'Jun', '2024-06-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//            CALL query_loan_schedule(  'new', '47', 'Jul', '2024-07-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
//             CALL query_loan_schedule(  'new', '47', 'Aug', '2024-08-01', 'BIT-LN-53', 'unpaid', '2023-08-31', '19',87500, 'l-14', 'Normal Loan', '1000', '');
