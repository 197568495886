// import { themeClass } from "variables";
import { Card, CardBody } from "reactstrap";
import { CustomTable } from "components/UI";
import { SearchBar } from "components/UI";
import { CustomButton } from "components/UI";
import { useHistory } from "react-router";
import { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { EmptyList } from "../Admin/ReportDetails";
import { getRoleLink } from "views/helper";
import { _fetchApi } from "redux/actions/api";
import toast from "react-hot-toast";
import { _postApi } from "redux/actions/api";
import { _deleteApi } from "redux/actions/api";
import { useSelector } from "react-redux";

const isAdmin = location?.pathname?.includes("/admin/");

const Announcement_Details = () => {
  const history = useHistory();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const society = useSelector((state) => state.auth.society);

  const handleEdit = (item) => {
    setSelectedAnnouncement(item);
    history.push(`/admin/announcement/edit/${item.id}`);
  };

  const fetchannouncement = () => {
    setLoading(true);
    _postApi(
      `/getannouncement?query_type=get&society_id=${society.id}`,
      {},
      (response) => {
        setLoading(false);
        if (response.success) {
          setAnnouncements(response.results);
        } else {
          // toast.e  rror(response.message || "Failed to fetch announcements.");
        }
      },
      (error) => {
        setLoading(false);
        // toast.error("Error fetching announcements.");
      }
    );
  };

  useEffect(() => {
    fetchannouncement();
  }, []);

  const handleUpdate = () => {
    _postApi(
      "/updateannouncement",
      { announcements: [selectedAnnouncement] },
      (response) => {
        if (response.success) {
          toast.success("Announcement updated successfully!");
          fetchAnnouncements();
        } else {
          toast.error("Failed to update announcement.");
        }
      },
      (error) => {
        toast.error("Error updating announcement.");
      }
    );
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this announcement?"
    );
    if (confirmDelete) {
      _postApi(
        `/getannouncement?query_type=delete&society_id=${society.id}`,
        { id: item.id },
        (response) => {
          if (response.success) {
            toast.success("Announcement deleted successfully!");
            fetchannouncement(); // Refresh the announcement list
          } else {
            toast.error(response.message || "Failed to delete announcement.");
          }
        },
        (error) => {
          toast.error("Error deleting announcement!");
        }
      );
    }
  };

  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, index) => <div>{index + 1}</div>,
      // component: (item) => <div>{item.id}</div>,
      value: "head",
    },
    { title: "Date From", value: "date_from" },
    { title: "Date To", value: "date_to" },

    {
      title: "Message",
      // custom: true,
      value: "message",
    },
    {
      title: "Action",
      custom: true,
      component: (item) =>
        isAdmin ? (
          <div className="text-center">
            <CustomButton size="sm" onClick={() => handleEdit(item)}>
              Edit
            </CustomButton>
            <CustomButton size="sm" onClick={() => handleDelete(item)}>
              Delete
            </CustomButton>
          </div>
        ) : null,
    },
  ].filter(Boolean);

  return (
    <>
      <Card color="white">
        <CardBody>
          {isAdmin ? (
            <CustomButton
              className="px-6 mb-3"
              onClick={() => history.push("/admin/announcement/new")}
            >
              Create New Announcement
            </CustomButton>
          ) : null}
          {/* {JSON.stringify(announcements)}  */}

          <SearchBar placeholder="Search for an announcement" />

          {announcements?.length > 0 ? (
            <CustomTable fields={fields} data={announcements} />
          ) : (
            <>
              !loading && <CustomTable fields={fields} />
              <EmptyList message="No any annoucement has been made yet, click on the button above to make the announcement." />
            </>
          )}
        </CardBody>
        {/* <h1>huygrhljsfn u4hjkrbgsvkrshjdfhbsehkjdrgvsrjhm</h1> */}
      </Card>
    </>
  );
};

export default Announcement_Details;
