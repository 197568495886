import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Card, CardTitle } from "reactstrap";
import { SelectInput } from "components/UI";
import MemberListInput from "../utilities/MemberListInput";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { CustomTable } from "components/UI";
import { getMembers } from "redux/actions/society";
import { getSummaryTransaction } from "../transaction-history/api";
import { ExportCSV } from "../utilities/ExportCSV";
import { formatNumber } from "utils/helpers";
import { SearchBar } from "components/UI";

function LoanSummary() {
  const today = moment().format("YYYY-MM-DD");
  const aYearAgo = moment(today).subtract(1, "year").format("YYYY-MM-DD");
  const {
    auth: { user, society },
    society: { members },
  } = useSelector((s) => s);

  const [list, setList] = useState([]);
  const [form, setForm] = useState({
    user_id: user.id,
    from: aYearAgo,
    to: today,
  });

  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getHistory = useCallback(() => {
    setLoading(true);
    getSummaryTransaction(
      {
        society_id: society.id,
        from: form.from,
        to: form.to,
        query_type: "loan_summary",
      },
      (d) => {
        setLoading(false);
        setList(d.data);
      }
    );
  }, [society.id, form.to, form.from]);

  useEffect(() => {
    getHistory();
  }, [getHistory, getHistory]);

  const tableFields = [
    {
      title: "Date",
      custom: true,
      component: (i) => <div>{moment(i?.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Name", value: "member_name" },
    { title: "Description", value: "loan_title" },
    {
      title: "Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.amount)}</div>
      ),
    },
    {
      title: "Amount paid",
      custom: true,
      component: (i) => (
        <div className="text-center">{formatNumber(i.amount_paid)}</div>
      ),
    },

    {
      title: "Total interest",
      custom: true,
      component: (i) => <div className="text-center">{i.total_interest}</div>,
    },

    {
      title: "Admin charges",
      custom: true,
      component: (i) => (
        <div className="text-center">{i.total_admin_charges}</div>
      ),
    },

    {
      title: "Period",
      custom: true,
      component: (i) => <div className="text-center">{i.period_value}</div>,
    },
    {
      title: "Balance",
      custom: true,
      component: (i) => {
        let bal = parseFloat(i.amount) - parseFloat(i.amount_paid);
        return <div className="text-center">{formatNumber(bal)}</div>;
      },
    },
  ];
  // total_interest":null,"total_admin_charges":10,"period_value":12,
  const rows = [];
  list &&
    list.forEach((item) => {
      if (
        item?.member_name?.toLowerCase().indexOf(filterText.toLowerCase()) ===
        -1
      )
        return;

      rows.push(item);
      // }
    });

  const totalDebit =
    rows && rows?.reduce((item, id) => item + parseFloat(id.amount), 0);

  return (
    <>
      {/* {JSON.stringify(rows)} */}
      <Card body>
        <CardTitle className="text-center h4 mb-2">
          <div className="row">
            <div className="col-md-3">Summary Loan History</div>

            <div className="col-md-4 text-right">
              Total Loan: <b>{formatNumber(totalDebit)}</b>
            </div>
            <div className="col-md-3">
              <ExportCSV
                csvData={rows}
                fileName={`Loan history from ${form.from} - ${form.to}`}
              />
            </div>
          </div>
        </CardTitle>

        <div className="row">
          <div className="col-md-6">
            <DaterangeSelector
              from={form.from}
              to={form.to}
              handleChange={handleRange}
              space={false}
            />
          </div>
          <div className="col-md-6">
            <SearchBar
              placeholder="Search by member's name"
              filterText={filterText}
              onFilterTextChange={(e) => {
                setFilterText(e);
              }}
            />
          </div>
        </div>

        <CustomTable fields={tableFields} data={rows || []} />
      </Card>
    </>
  );
}

export default LoanSummary;
