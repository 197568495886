import store from "../store";

let localEndpoint = "http://localhost:48322";
export const ipAddr = "127.0.0.1";
// let remoteEndpoint = "https://results-associates.co/server/bitscoops-backend";
let remoteEndpoint = "https://galaxybis.ebudgetkano.ng/bitcoops";
export const api_url =
  process.env.NODE_ENV === "production"
    ? remoteEndpoint + "/api"
    : localEndpoint + "/api";

//"https://yge.wvi.mybluehost.me/test/coop-soc-backend";
